import './Lightbox.scss'

import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import Helmet from 'react-helmet'
import { Portal } from 'react-portal'
import { useDispatch } from 'react-redux'

const Lightbox = ({ children, className, id, linkText, linkClass }) => {
  const lightboxRef = useRef(null)

  const [open, setOpen] = useState(false)

  const dispatch = useDispatch()

  const handleOpen = () => {
    setOpen(true)
    dispatch({ type: 'LIGHTBOX_OPEN' })
  }
  useEffect(() => {
    open && lightboxRef.current && lightboxRef.current.focus()
  }, [open])

  const closeDuration = 250
  const handleClose = () => {
    lightboxRef.current.style.cssText = `animation: fade-out ${closeDuration}ms ease; opacity: 0`
    setTimeout(() => {
      setOpen(false)
      dispatch({ type: 'LIGHTBOX_CLOSE' })
    }, closeDuration)
  }
  useEffect(() => {
    return () => {
      dispatch({ type: 'LIGHTBOX_CLOSE' })
    }
  }, [dispatch])

  const escFunction = e => {
    if (e.keyCode === 27 && open) {
      handleClose()
    }
  }
  useEffect(() => {
    document.addEventListener('keydown', escFunction, false)
    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  })

  return (
    <>
      <div
        className={`lightbox-link${linkClass ? ' ' + linkClass : ''}`}
        onClick={handleOpen}
        onKeyPress={handleOpen}
        role="button"
        aria-label="open lightbox"
        tabIndex={0}
      >
        {linkText && linkText}
      </div>
      {open && (
        <Portal
          node={
            typeof document !== 'undefined' &&
            document.getElementById('___gatsby')
          }
        >
          <Helmet htmlAttributes={{ class: 'lightbox-open' }} />
          <div
            className={`lightbox ${className || ''}`}
            id={id || ''}
            ref={lightboxRef}
            role="dialog"
            tabIndex={-1}
          >
            <div className="lightbox-wrapper">
              <div className="wrapper-inner">
                <div
                  className="lightbox-bg"
                  onClick={handleClose}
                  role="none"
                ></div>
                <div className="lightbox-container">
                  <div className="lightbox-content">{children}</div>
                  <div
                    className="close-button"
                    onClick={handleClose}
                    onKeyPress={handleClose}
                    aria-label="close"
                    role="button"
                    tabIndex={0}
                  />
                </div>
              </div>
            </div>
          </div>
        </Portal>
      )}
    </>
  )
}

export default Lightbox

Lightbox.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  linkText: PropTypes.string,
  linkClass: PropTypes.string,
}
