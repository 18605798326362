import '../styles/network-site.scss'

import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import React, { useEffect, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import smoothscroll from 'smoothscroll-polyfill'

import Lightbox from '../components/Lightbox'
import Parallax from '../components/Parallax'
import SEO from '../components/SEO'
import VideoHero from '../components/VideoHero'
import windowSize from '../hooks/windowSize'
import INWLogo from '../images/inw_logo_color.inline.svg'
import PlayButton from '../images/play_button_small.inline.svg'
import Layout from './Layout'

export const data = graphql`
  query ($siteName: String!) {
    datoCmsNetworkSite(siteName: { eq: $siteName }) {
      siteName
      logoLine1
      logoLine2
      lede
      ledeNode {
        childMarkdownRemark {
          html
        }
      }
      lightboxVideo {
        url
      }
      heroBackgroundVideo {
        url
      }
      heroBackgroundCover {
        fluid(maxWidth: 1440) {
          ...GatsbyDatoCmsFluid
        }
      }
      address {
        address
        addressLink
      }
      # city
      # state
      # zip
      # addressLink
      telephone
      email
      capabilitiesHeadline
      capabilitiesTextNode {
        childMarkdownRemark {
          html
        }
      }
      capabilitiesImage {
        fluid(maxWidth: 840) {
          ...GatsbyDatoCmsFluid
        }
      }
      productCategoriesNode {
        childMarkdownRemark {
          html
        }
      }
      productFormsNode {
        childMarkdownRemark {
          html
        }
      }
      productsNode {
        childMarkdownRemark {
          html
        }
      }
      fillingCapabilitiesNode {
        childMarkdownRemark {
          html
        }
      }
      advancedSiteAttributesNode {
        childMarkdownRemark {
          html
        }
      }
      onSiteLaboratoriesNode {
        childMarkdownRemark {
          html
        }
      }
      industryCertificationsNode {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`

const NetworkSiteLayout = ({ data }) => {
  const dato = data.datoCmsNetworkSite
  const getHtml = node => node.childMarkdownRemark.html

  const { innerWidth } = windowSize()

  const listsRef = useRef(null)
  const [activeList, setActiveList] = useState(null)

  const handleToggleList = i => {
    if (innerWidth <= 600) {
      if (i !== activeList) {
        setActiveList(i)
        if (activeList !== null) {
          // Scroll into view if pushed above viewport, wait for previous animation to finish
          smoothscroll.polyfill()
          setTimeout(() => {
            if (
              listsRef.current.children[i].getBoundingClientRect().top <
              60
            ) {
              window.scrollBy({
                top:
                  listsRef.current.children[i].getBoundingClientRect()
                    .top - 60,
                behavior: 'smooth',
              })
            }
          }, 500)
        }
      } else {
        setActiveList(null)
      }
    }
  }

  useEffect(() => {
    // Make accordian lists on mobile
    const lists = [...listsRef.current.children]
    lists.forEach((list, i) => {
      const height = list.querySelector('.list-contents').offsetHeight
      const listWrap = list.querySelector('.list-contents-wrap')
      if (innerWidth <= 600) {
        if (i === activeList) {
          listWrap.style.transition =
            'max-height 500ms ease 0s, opacity 300ms ease 0ms'
          listWrap.style.maxHeight = height + 'px'
          listWrap.style.opacity = 1
        } else {
          listWrap.style.transition =
            'max-height 500ms ease 0s, opacity 300ms ease 200ms'
          listWrap.style.maxHeight = 0
          listWrap.style.opacity = 0
        }
      } else {
        listWrap.style.transition = 'none'
        listWrap.style.maxHeight = 'auto'
        listWrap.style.opacity = 1
      }
    })
  }, [innerWidth, activeList])

  return (
    <Layout navReversed id="network-site">
      <SEO title={dato.siteName} description={dato.lede} />
      <VideoHero
        url={dato.heroBackgroundVideo.url}
        coverImage={dato.heroBackgroundCover.fluid}
      >
        <div className="logo">
          <INWLogo />
          <h1 className="site-name">
            <span>{dato.logoLine1}</span>
            <span>{dato.logoLine2}</span>
          </h1>
        </div>
        <div
          className="lede"
          dangerouslySetInnerHTML={{ __html: getHtml(dato.ledeNode) }}
        />
        {dato.lightboxVideo && (
          <div className="video-link">
            <PlayButton className="play-button" /> Watch:{' '}
            {dato.siteName} in action
            <Lightbox className="video-lightbox">
              <ReactPlayer
                url={dato.lightboxVideo.url}
                playing
                config={{
                  vimeo: {
                    preload: true,
                    playerOptions: {
                      title: false,
                      byline: false,
                      speed: false,
                      playsinline: true,
                      color: '87C33F',
                      responsive: true,
                      transparent: true,
                      autopause: false,
                      controls: true,
                      dnt: true,
                    },
                  },
                }}
                width="100%"
                height="100%"
                style={{ position: 'absolute' }}
              />
            </Lightbox>
          </div>
        )}
      </VideoHero>

      <section id="contact-bar">
        <div className="details">
          <div className="address">
            {dato.address &&
              dato.address.map((node, i) => (
                <a
                  href={node.addressLink}
                  key={i}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>{node.address}</span>
                </a>
              ))}
          </div>
        </div>
        <div className="phone-email">
          <a href={`tel: ${dato.telephone}`}>{dato.telephone}</a>
          {/* <a href={`mailto: ${dato.email}`} target="_blank" rel="noopener noreferrer"> */}
          {dato.email}
          {/* </a> */}
          {/* <a href={`mailto: ${dato.email}`} target="_blank" rel="noopener noreferrer">
            {dato.email}
          </a> */}
        </div>
        {/* <div className="links">
          <Link to="/contact#form" state={{ name: dato.siteName, email: dato.email }}>
            Contact {dato.siteName}
          </Link>
        </div> */}
      </section>

      <section id="capabilities">
        <div className="intro">
          <div className="content">
            <h2>{dato.capabilitiesHeadline}</h2>
            <div
              dangerouslySetInnerHTML={{
                __html: getHtml(dato.capabilitiesTextNode),
              }}
            />
          </div>
          <div className="image">
            <Parallax factor={0.05}>
              <Img
                fluid={dato.capabilitiesImage.fluid}
                style={{ overflow: 'visible', position: 'absolute' }}
                imgStyle={{
                  top: '50%',
                  left: '0%',
                  width: 'auto',
                  minWidth: '100%',
                  height: 'auto',
                  minHeight: '80%',
                  objectFit: 'cover',
                  objectPosition: '0% 50%',
                  transform: 'translate(0%, -50%)',
                }}
              />
            </Parallax>
          </div>
        </div>
        <div
          id="capabilities-lists"
          ref={listsRef}
          // style={dato.siteName === 'Living Ecology' ? { display: 'none' } : {}}
        >
          <div
            className="list products"
            role="button"
            tabIndex={0}
            onKeyPress={() => handleToggleList(0)}
            onClick={() => handleToggleList(0)}
          >
            <h5>Product Categories</h5>
            <div className="list-contents-wrap">
              <div
                className="list-contents categories-list"
                dangerouslySetInnerHTML={{
                  __html: getHtml(dato.productCategoriesNode),
                }}
              />
            </div>
          </div>

          <div
            className="list products"
            role="button"
            tabIndex={0}
            onKeyPress={() => handleToggleList(1)}
            onClick={() => handleToggleList(1)}
          >
            <h5>Product Forms</h5>
            <div className="list-contents-wrap">
              <div
                className="list-contents"
                dangerouslySetInnerHTML={{
                  __html: getHtml(dato.productFormsNode),
                }}
              />
            </div>
          </div>

          <div
            className="list products"
            role="button"
            tabIndex={0}
            onKeyPress={() => handleToggleList(2)}
            onClick={() => handleToggleList(2)}
          >
            <h5>Filling Capabilities</h5>
            <div className="list-contents-wrap">
              <div
                className="list-contents"
                dangerouslySetInnerHTML={{
                  __html: getHtml(dato.fillingCapabilitiesNode),
                }}
              />
            </div>
          </div>

          <div
            className="list products"
            role="button"
            tabIndex={0}
            onKeyPress={() => handleToggleList(3)}
            onClick={() => handleToggleList(3)}
          >
            <h5>Products</h5>
            <div className="list-contents-wrap">
              <div
                className="list-contents"
                dangerouslySetInnerHTML={{
                  __html: getHtml(dato.productsNode),
                }}
              />
            </div>
          </div>

          <div
            className="list attributes"
            role="button"
            tabIndex={0}
            onKeyPress={() => handleToggleList(4)}
            onClick={() => handleToggleList(4)}
          >
            <h5>Facility Attributes</h5>
            <div className="list-contents-wrap">
              <div
                className="list-contents"
                dangerouslySetInnerHTML={{
                  __html: getHtml(dato.advancedSiteAttributesNode),
                }}
              />
            </div>
          </div>

          <div
            className="list attributes"
            role="button"
            tabIndex={0}
            onKeyPress={() => handleToggleList(5)}
            onClick={() => handleToggleList(5)}
          >
            <h5>On-Site Laboratories</h5>
            <div className="list-contents-wrap">
              <div
                className="list-contents"
                dangerouslySetInnerHTML={{
                  __html: getHtml(dato.onSiteLaboratoriesNode),
                }}
              />
            </div>
          </div>

          <div
            className="list attributes"
            role="button"
            tabIndex={0}
            onKeyPress={() => handleToggleList(6)}
            onClick={() => handleToggleList(6)}
          >
            <h5>Industry Certifications</h5>
            <div>
              <div className="list-contents-wrap">
                <div
                  className="list-contents"
                  dangerouslySetInnerHTML={{
                    __html: getHtml(dato.industryCertificationsNode),
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NetworkSiteLayout
