import './VideoHero.scss'

import Img from 'gatsby-image'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useRef, useState } from 'react'

import InlineVideo from './InlineVideo'
import ParallaxBackground from './ParallaxBackground'

const VideoHero = ({ children, url, coverImage, className }) => {
  const heroRef = useRef(null)
  const [height, setHeight] = useState(0)

  const handleSizing = useCallback(() => {
    // Set the min-width of the video wrap.
    // Must be delayed for some reason (???) else it calculates the wrong wrapHeight sometimes
    setTimeout(() => {
      const wrapHeight =
        heroRef.current &&
        heroRef.current.parentElement.getBoundingClientRect().height
      setHeight(wrapHeight)
      const element =
        heroRef.current &&
        heroRef.current.querySelector('.parallax-bg-wrap')
      element.style.minWidth = (height * 16) / 9 + 'px'
    }, 250)
  }, [heroRef, height])

  useEffect(handleSizing, [handleSizing])

  return (
    <section id="hero" className="video-hero">
      <div className="content">{children}</div>
      <div className="video-hero-background" ref={heroRef}>
        {coverImage && (
          <Img className="cover-image" fluid={coverImage} />
        )}
        <ParallaxBackground className={className || ''}>
          <InlineVideo
            className={'video'}
            url={url}
            width="100%"
            height="100%"
          />
        </ParallaxBackground>
      </div>
    </section>
  )
}

export default VideoHero

VideoHero.propTypes = {
  children: PropTypes.node.isRequired,
  url: PropTypes.string.isRequired,
  coverImage: PropTypes.object,
  className: PropTypes.string,
}
